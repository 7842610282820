import React from "react"
import { Switch, Route } from "react-router-dom"

import { useKeycloak } from "@react-keycloak/web"

import ProtectedRoute from "./ProtectedRoute"
import PageNotFound from "../components/PageNotFound"

import { navigation as navAdmin } from "./index"

const AdminRoute = (props) => {
  /**
   * generate admin route
   * @param data
   * @param index
   * @returns {null|*}
   */
  const generateAdminRoute = (data, index) => {
    if (!data) {
      return null
    }

    if (data.children && data.children.length > 0) {
      return data.children.map((item, index) => generateAdminRoute(item, index))
    }

    if (!data.component) {
      return null
    }

    if (data.unProtected) {
      return (
        <Route
          {...props}
          key={index}
          path={data.path}
          component={data.component}
          exact={data.isExactly}
        />
      )
    }

    return (
      <ProtectedRoute
        {...props}
        exact={data.isExactly}
        key={() => Math.random()}
        path={data.path}
        component={data.component}
        resource={data.resource}
        action={data.actionType}
      />
    )
  }

  /**
   * generate default page
   * @returns {*}
   */
  const generateDefaultPage = () => {
    return <Route render={() => <PageNotFound />} />
    // return <Redirect to={`${routes.LOGIN}`} />
  }

  const [, initialized] = useKeycloak()

  if (!initialized) {
    return <div>Loading...</div>
  }

  console.log("navAdmin", navAdmin)

  return (
    <Switch>
      {navAdmin.map((data, index) => generateAdminRoute(data, index))}
      {generateDefaultPage()}
      {/* <Route
        path='/authorization/login'
        component={LoadableComponent(() =>
          import('../views/Authorization/Login')
        )}
      /> */}
    </Switch>
  )
}

export default AdminRoute
