import React, { useCallback, useEffect, useState } from 'react'
// lib
import { useDispatch, useSelector } from 'react-redux'
import { Menu, Dropdown, Avatar, Badge } from 'antd'
import { useKeycloak } from '@react-keycloak/web'
// action
// import authActions from '../../../actions/authActions'
import stringExtensions from '../../../extensions/string'
// component
import FormModal from '../../FormModal'
// import ChangePasswordForm from './ChangePassword'
import { stringHelper } from '../../../extensions'

const ProfileMenu = () => {
  const { keycloak } = useKeycloak()
  const [openModal, setOpenModal] = useState(false)
  const [userInfo, setUserInfo] = useState({
    userName: 'Guest',
    email: null
  })

  const state = useSelector((state) => ({
    auth: state.auth,
    formError: state.form
  }))

  // map dispatch from redux to local
  const dispatch = useDispatch()

  useEffect(() => {
    if (state.auth && state.auth.user) {
      const { user } = state.auth
      setUserInfo({
        userName: user.name,
        email: user.mail
        //email: stringHelper.decodeEmailAddress(user.emailEncrypt)
      })
    }
  }, [state.auth])

  const handleLogout = () => {
    if (!!keycloak.authenticated) {
      keycloak.logout()
    }
  }

  const handleChangePassword = (values) => {
    // changePassword(userInfo.userName, values.oldPass, values.newPass)
    setOpenModal(false)
  }

  const generateUserMenu = (userInfo) => (
    <Menu selectable={false}>
      <Menu.Item>
        <div className='rfq__widget__system-status__item'>
          <strong>Hello, {userInfo.userName}</strong>
        </div>
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item>
        <div className='rfq__widget__system-status__item'>
          <strong>Email:</strong> {userInfo.email}
        </div>
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item>
        <div className='rfq__widget__system-status__item'>
          <a onClick={() => setOpenModal(true)}>Change Password</a>
        </div>
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item>
        <a onClick={handleLogout}>
          <i className='topbar__dropdownMenuIcon icmn-exit' /> Logout
        </a>
      </Menu.Item>
    </Menu>
  )

  // render
  return userInfo ? (
    <div className='topbar__dropdown d-inline-block'>
      <Dropdown
        overlay={generateUserMenu(userInfo)}
        trigger={['click']}
        // placement="bottomRight"
      >
        <a className='ant-dropdown-link' href='/'>
          <Avatar size='large' className='text-uppercase' shape='square'>
            {stringExtensions.getAbbreviate(userInfo.userName)}
          </Avatar>
        </a>
      </Dropdown>
      <FormModal
        customClass='modal-lg'
        open={openModal}
        handleCloseModal={() => setOpenModal(false)}
        title={'Change Password'}
      >
        {/* <ChangePasswordForm
          toggle={openModal}
          handleSubmit={handleChangePassword}
        /> */}
      </FormModal>
    </div>
  ) : null
}

export default ProfileMenu
