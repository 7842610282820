// lib
import moment from 'moment'
import {
  DEFAULT_FORMAT_DATE,
  DEFAULT_FORMAT_DATE_EN,
  DEFAULT_FORMAT_DATE_TIME,
  DEFAULT_ISO_FORMAT_DATE,
} from '../constants'

const INFINITY_DATE = new Date(9999, 11, 31, 23, 59, 59, 0)

export default {
  initNewDate(currentDate, nullable) {
    if (currentDate) {
      return moment(currentDate)
    }
    if (!nullable) {
      return moment(new Date())
    }
    return null
  },

  initNewVnDate(currentDate, defaultDate, nullable) {
    if (currentDate) {
      return moment(currentDate, DEFAULT_FORMAT_DATE)
    }
    if (defaultDate) {
      return defaultDate
    }
    if (!nullable) {
      return moment(new Date())
    }
    return null
  },

  initNewVnDateTime(currentDate, nullable) {
    if (currentDate) {
      return moment(currentDate, DEFAULT_FORMAT_DATE_TIME).format()
    }
    if (!nullable) {
      return moment(new Date()).format()
    }
    return null
  },

  initNewDateFromFormat(currentDate, format) {
    if (currentDate) {
      return moment(currentDate, format).format()
    }

    return null
  },

  formatStartOfDay(currentDate, nullable, lastMonth) {
    if (currentDate) {
      return moment(currentDate)
        .startOf('days')
        .utc()
        .format()
    }
    if (!nullable) {
      return moment(new Date())
        .startOf('days')
        .utc()
        .format()
    }
    if (lastMonth) {
      return moment(new Date())
        .startOf('months')
        .utc()
        .format()
    }
    return null
  },

  formatEndOfDay(currentDate, nullable) {
    if (currentDate) {
      return moment(currentDate)
        .endOf('days')
        .utc()
        .format()
    }
    if (!nullable) {
      return moment(new Date())
        .endOf('days')
        .utc()
        .format()
    }
    return null
  },

  /**
   * DD.MM.YYYY
   * @param currentDate
   * @param nullable
   * @returns {string|null}
   */
  formatVnDate(currentDate, nullable) {
    if (currentDate) {
      return moment(currentDate).format(DEFAULT_FORMAT_DATE)
    }

    if (!nullable) {
      return moment(new Date()).format(DEFAULT_FORMAT_DATE)
    }
    return null
  },

  formatVnDateTime(currentDate, nullable) {
    if (currentDate) {
      return moment(currentDate).format(DEFAULT_FORMAT_DATE_TIME)
    }

    if (!nullable) {
      return moment(new Date()).format(DEFAULT_FORMAT_DATE_TIME)
    }
    return null
  },

  /**
   * MM.DD.YYYY
   * @param currentDate
   * @param nullable
   * @returns {string|null}
   */
  formatEnDate(currentDate, nullable) {
    if (currentDate) {
      return moment(currentDate).format(DEFAULT_FORMAT_DATE_EN)
    }

    if (!nullable) {
      return moment(new Date()).format(DEFAULT_FORMAT_DATE_EN)
    }
    return null
  },

  formatOnlyDate(currentDate) {
    return currentDate ? moment(currentDate).format('YYYY-MM-DD') : null
  },

  checkIsPreviousDate(currentDate, dateCheck) {
    return !!(
      currentDate &&
      dateCheck &&
      moment(currentDate).isBefore(moment(dateCheck))
    )
  },

  checkIsFutureDate(currentDate, dateCheck) {
    return !!(
      currentDate &&
      dateCheck &&
      moment(currentDate).isAfter(moment(dateCheck))
    )
  },

  subtractDuration(currentDate, subtract, type) {
    return currentDate ? moment(currentDate).subtract(subtract, type) : null
  },

  calculateVnYearDiff(currentDate, dateDiff) {
    if (currentDate && dateDiff) {
      return moment(currentDate, DEFAULT_FORMAT_DATE).diff(
        moment(dateDiff, DEFAULT_FORMAT_DATE),
        'years'
      )
    }
    return null
  },

  addDuration(currentDate, add, durationType) {
    if (currentDate) {
      const dateWithDuration = moment(currentDate).add(add, durationType)
      if (this.checkIsFutureDate(dateWithDuration, INFINITY_DATE)) {
        return moment(INFINITY_DATE).format()
      } else {
        return dateWithDuration.format()
      }
    }
    return null
  },

  getUserTimezone(currentDate, allowNull) {
    const timeZone = new Date().getTimezoneOffset()
    if (!currentDate) {
      return !allowNull
        ? moment(new Date())
            .subtract(timeZone, 'minutes')
            .format()
        : null
    }
    return moment(currentDate)
      .subtract(timeZone, 'minutes')
      .format()
  },

  getUserVnTime({ currentDate, nullable, startOfDay, endOfDay }) {
    const timeZone = new Date().getTimezoneOffset()
    if (currentDate) {
      return moment(currentDate, DEFAULT_FORMAT_DATE_TIME)
        .add(timeZone, 'minutes')
        .format()
    }
    if (!nullable) {
      if (startOfDay) {
        return moment(new Date())
          .startOf('days')
          .add(timeZone, 'minutes')
          .format()
      }
      if (endOfDay) {
        return moment(new Date())
          .endOf('days')
          .add(timeZone, 'minutes')
          .format()
      }
      return moment(new Date())
        .add(timeZone, 'minutes')
        .format()
    }
    return null
  },

  getDateRangeVnDateTime({ startDate, endDate, allowNull }) {
    const start = this.getUserVnTime({
      currentDate: startDate,
      nullable: allowNull,
      startOfDay: true,
    })
    const end = this.getUserVnTime({
      currentDate: endDate,
      nullable: allowNull,
      endOfDay: true,
    })
    return {
      startDate: start,
      endDate: end,
    }
  },

  checkValidDate(date) {
    if (typeof date !== 'string') {
      return false
    }
    const currentDate = moment(date, moment.ISO_8601)
    return currentDate.isValid()
  },

  getStartUnitOfTime(currentDate, unit) {
    return currentDate
      ? moment(currentDate).startOf(unit)
      : moment(new Date()).startOf(unit)
  },

  getEndUnitOfTime(currentDate, unit) {
    return currentDate
      ? moment(currentDate).endOf(unit)
      : moment(new Date()).endOf(unit)
  },

  getFirstDayOfMonth() {
    return moment(new Date())
      .startOf('months')
      .format(DEFAULT_ISO_FORMAT_DATE)
  },
  getCurrentDay() {
    return moment(new Date())
      .endOf('days')
      .format(DEFAULT_ISO_FORMAT_DATE)
  },
}
