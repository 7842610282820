import React, { Fragment, useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { BackTop, Layout as AntLayout } from 'antd'
import { Spinner } from 'react-redux-spinner'
import { enquireScreen, unenquireScreen } from 'enquire-js'
import { ContainerQuery } from 'react-container-query'
import classNames from 'classnames'

// routes
import screenWidth from '../../constants/screenWidth'

// components
import Notifications from '../Notification'
import Menu from './Menu'
import TopBar from './TopBar'
import Breadcrumb from './TopBar/Breadcrumb'

// routes
import AdminRoute from '../../routes/AdminRoute'
import { useKeycloak } from '@react-keycloak/web'

const AntContent = AntLayout.Content
const AntHeader = AntLayout.Header

let isMobile
enquireScreen((b) => {
  isMobile = b
})

const AdminArea = (props) => {
  const [menuMobileOpened, setMenuMobileOpened] = useState(false)
  const [menuCollapsed, setMenuCollapsed] = useState(true)
  const [themeLight, setThemeLight] = useState(true)
  const [settingsOpened, setSettingsOpened] = useState(false)
  const [mobileScreen, setMobileScreen] = useState(props.isMobile)

  // const state = useSelector((state) => ({
  //   auth: state.auth
  // }))

  useEffect(() => {
    enquireScreen((mobile) => setMobileScreen(mobile))
  }, [])

  useEffect(() => {
    return () => {
      unenquireScreen(enquireScreen((mobile) => setMobileScreen(mobile)))
    }
  }, [])

  useEffect(() => {
    if (isMobile) {
      setMenuMobileOpened(false)
      setMenuCollapsed(true)
    }
  }, [props.location])

  const handleToggleMenuMobileOpen = () => {
    setMenuMobileOpened(!menuMobileOpened)
  }

  const handleMenuMobileOpen = () => {
    setMenuMobileOpened(false)
  }

  const handleToggleSettingOpened = () => {
    setSettingsOpened(!settingsOpened)
  }

  const handleMenuCollapseMobile = () => {
    setMenuCollapsed(false)
  }

  const handleToggleMenuCollapse = () => {
    setMenuCollapsed(!menuCollapsed)
  }

  const { history, location } = props
  const isMobile = !!mobileScreen
  const pathname = location?.pathname
  // const auth = state?.auth
  // const isAuthorization = true

  const [keycloak] = useKeycloak()

  const isAuthenticated = keycloak.authenticated

  return (
    <Fragment>
      <ContainerQuery query={screenWidth}>
        {(params) => (
          <div className={classNames(params)}>
            <AntLayout
              className={classNames('flex-row', {
                settings__borderLess: true,
                settings__squaredBorders: false,
                settings__fixedWidth: false,
                settings__menuShadow: true,
                settings__menuTop: false
              })}
            >
              <Spinner />

              <BackTop />

              {
                isAuthenticated
                  ? <Menu
                    themeLight={themeLight}
                    isMobile={isMobile}
                    pathname={pathname}
                    menuMobileOpened={menuMobileOpened}
                    menuCollapsed={menuCollapsed}
                    handleToggleMenuMobileOpen={handleToggleMenuMobileOpen}
                    handleMenuMobileOpen={handleMenuMobileOpen}
                    handleToggleSettingOpened={handleToggleSettingOpened}
                    handleMenuCollapseMobile={handleMenuCollapseMobile}
                    handleToggleMenuCollapse={handleToggleMenuCollapse}
                  />
                  : null
              }


              <AntLayout>
                {
                  isAuthenticated
                    ? <AntHeader>
                      <TopBar {...props} history={history} isMobile={isMobile} />

                    </AntHeader>
                    : null
                }

                <AntContent style={{ height: '100%', position: 'relative' }}>
                  {/* {
                    isAuthenticated
                      ? <Breadcrumb
                        {...props}
                        history={history}
                        isMobile={isMobile}
                      />
                      : null
                  } */}

                  <div className={classNames({ utils__content: true })}>
                    <AdminRoute {...props} isMobile={isMobile} />
                  </div>
                </AntContent>
              </AntLayout>
            </AntLayout>
          </div>
        )}
      </ContainerQuery>
      <Notifications />
    </Fragment>
  )
}

export default withRouter(AdminArea)
