import ActionTypes from '../actions/types'
import { updateRecursiveArray } from '../services/mixType'

const initState = {
  listMixTypes: []
}

const ProductReducer = (state = initState, action) => {
  switch (action.type) {
    case ActionTypes.FETCHING_PRODUCT_SUCCESS: {
      return Object.assign(state, {
        listMixTypes: action.payload?.data ?? []
      })
    }

    case ActionTypes.UPDATE_CATEGORY_SUCCESS:
    case ActionTypes.UPDATE_PRODUCT_SUCCESS:
    case ActionTypes.UPDATE_PROPERTY_SUCCESS: {
      const currentLists = updateRecursiveArray(
        state.listMixTypes,
        action.payload.data
      )

      return Object.assign(state, {
        listMixTypes: currentLists
      })
    }
    default: {
      return state
    }
  }
}

export default ProductReducer
