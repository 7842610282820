import mainRoute from './mainRoute'
import LoadableComponent from '../components/LoadableComponent'

export const routes = {
  /**
   * USER
   */
  RIOT_KEY_LIST: `${mainRoute.RIOT_KEY}`,
  RIOT_KEY_EDIT: `${mainRoute.RIOT_KEY}/:id`,
  RIOT_KEY_CREATE: `${mainRoute.RIOT_KEY}/create`,
  RIOT_KEY_SESSION: `${mainRoute.RIOT_KEY}/session/:id`,
  /**
   * END USER
   */
  /**
   * FILE
   */
  FILE: `${mainRoute.FILE}`,
  // ORDER
  ORDER: `${mainRoute.ORDER}`,
  ORDER_DETAIL: `${mainRoute.ORDER}/:id`,
  TRANSACTION: `${mainRoute.TRANSACTION}`,

  // BLACKLIST
  BLACKLIST: `${mainRoute.BLACKLIST}`,

  // DEALER
  DEALER: `${mainRoute.DEALER}`,
  DEALER_CREATE: `${mainRoute.DEALER}/create`,

  //RESET PASSWORD
  RESET_PASSWORD: `${mainRoute.RESETPASSWORD}`,
  // STATISTIC PAGE
  STATISTIC: `${mainRoute.STATISTIC}`,
  // ACCOUNT PAGE
  ACCOUNT: `${mainRoute.ACCOUNT}`,
  ACCOUNT_TRANS_DETAIL: `${mainRoute.ACCOUNT}/transaction/:id`,
  ACCOUNT_ORDERS_DETAIL: `${mainRoute.ACCOUNT}/order/:id`,
  APPS: `${mainRoute.APPS}`,
  PRODUCTS: `${mainRoute.PRODUCTS}`,
  HTML_BLOCK: `${mainRoute.HTML_BLOCK}`,
  HTML_BLOCK_CREATE: `${mainRoute.HTML_BLOCK}/create`,
  HTML_BLOCK_EDIT: `${mainRoute.HTML_BLOCK}/:id`,
  CAMPAIGN: `${mainRoute.CAMPAIGN}`,
  CAMPAIGN_CREATE: `${mainRoute.CAMPAIGN}/create`,
  CAMPAIGN_EDIT: `${mainRoute.CAMPAIGN}/:id`,
  CATALOG: `${mainRoute.CATALOG}`,
  CATALOG_CREATE: `${mainRoute.CATALOG}/create`,
  CATALOG_EDIT: `${mainRoute.CATALOG}/:id`,

  LOGIN: `${mainRoute.AUTHORIZATION}/login`,
  HOME_PAGE: `${mainRoute.HOME_PAGE}`,
}

export const resources = {
  LOGIN: {
    title: 'Login',
    key: 'LOGIN',
  },

  // user
  RIOT_KEY_LIST: {
    title: 'Riot Keys',
    key: 'USER_LIST',
  },
  RIOT_KEY_EDIT: {
    title: 'Edit Riot Key',
    key: 'USER_EDIT',
  },
  RIOT_KEY_CREATE: {
    title: 'Create Riot Key',
    key: 'USER_CREATE',
  },
  RIOT_KEY_SESSION: {
    title: 'Riot Key Session',
    key: 'USER_SESSION',
  },
  FILE: {
    title: 'Files',
    key: 'Files',
  },
  ORDER: {
    title: 'Orders',
    key: 'Orders',
  },
  ORDER_DETAIL: {
    title: 'Order Detail',
    key: 'ORDER_DETAIL',
  },
  BLACKLIST: {
    title: 'Blacklists',
    key: 'Blacklists',
  },
  DEALER: {
    title: 'Dealers',
    key: 'Dealers',
  },
  CATALOG: {
    title: 'Catalogs',
    key: 'Catalogs',
  },
  CATALOG_CREATE: {
    title: 'Catalogs Create',
    key: 'CATALOG_CREATE',
  },
  CATALOG_EDIT: {
    title: 'Catalogs Edit',
    key: 'CATALOG_EDIT',
  },
  DEALER_CREATE: {
    title: 'Create Dealer',
    key: 'DEALER_CREATE',
  },
  RESET_PASSWORD: {
    title: 'Reset Password',
    key: 'RESET_PASSWORD',
  },
  STATISTIC: {
    title: 'Statistic Page',
    key: 'statistic',
  },
  ACCOUNT: {
    title: 'Account Page',
    key: 'account',
  },
  ACCOUNT_TRANS_DETAIL: {
    title: 'Account Transaction Page',
    key: 'ACCOUNT_TRANS_DETAIL',
  },
  ACCOUNT_ORDERS_DETAIL: {
    title: 'Account Order Page',
    key: 'ACCOUNT_ORDERS_DETAIL',
  },
  APPS: {
    title: 'Apps',
    key: 'Apps',
  },
  PRODUCTS: {
    title: 'Products',
    key: 'Products',
  },
  HTML_BLOCK: {
    title: 'HtmlBlock',
    key: 'HtmlBlock',
  },
  HTML_BLOCK_CREATE: {
    title: 'Create HtmlBlock',
    key: 'HTML_BLOCK_CREATE',
  },
  HTML_BLOCK_EDIT: {
    title: 'Edit HtmlBlock',
    key: 'HTML_BLOCK_EDIT',
  },
  TRANSACTION: {
    title: 'Transaction',
    key: 'Transaction',
  },
  CAMPAIGN: {
    title: 'Campaign',
    key: 'Campaign',
  },
  CAMPAIGN_CREATE: {
    title: 'Create Campaign',
    key: 'CAMPAIGN_CREATE',
  },
  CAMPAIGN_EDIT: {
    title: 'Edit Campaign',
    key: 'CAMPAIGN_EDIT',
  },
}

export const navigation = [
  // {
  //   name: resources.LOGIN.title,
  //   key: resources.LOGIN.key,
  //   resource: resources.LOGIN.key,
  //   path: routes.LOGIN,
  //   component: LoadableComponent(() => import('../views/Authorization/Login'))
  // },
  {
    name: resources.RIOT_KEY_CREATE.title,
    key: resources.RIOT_KEY_CREATE.key,
    resource: resources.RIOT_KEY_CREATE.key,
    path: routes.RIOT_KEY_CREATE,
    component: LoadableComponent(() => import('../views/User/create')),
  },
  {
    name: resources.RIOT_KEY_SESSION.title,
    key: resources.RIOT_KEY_SESSION.key,
    resource: resources.RIOT_KEY_SESSION.key,
    path: routes.RIOT_KEY_SESSION,
    component: LoadableComponent(() => import('../views/User/Session')),
  },
  {
    name: resources.RIOT_KEY_EDIT.title,
    key: resources.RIOT_KEY_EDIT.key,
    resource: resources.RIOT_KEY_EDIT.key,
    path: routes.RIOT_KEY_EDIT,
    component: LoadableComponent(() => import('../views/User/edit')),
  },
  {
    name: resources.RIOT_KEY_LIST.title,
    key: resources.RIOT_KEY_LIST.key,
    resource: resources.RIOT_KEY_LIST.key,
    path: routes.RIOT_KEY_LIST,
    component: LoadableComponent(() => import('../views/User')),
  },
  {
    name: resources.FILE.title,
    key: resources.FILE.key,
    resource: resources.FILE.key,
    path: routes.FILE,
    component: LoadableComponent(() => import('../views/File')),
  },
  {
    name: resources.ORDER_DETAIL.title,
    key: resources.ORDER_DETAIL.key,
    resource: resources.ORDER_DETAIL.key,
    path: routes.ORDER_DETAIL,
    component: LoadableComponent(() => import('../views/Order/detail')),
  },
  {
    name: resources.ORDER.title,
    key: resources.ORDER.key,
    resource: resources.ORDER.key,
    path: routes.ORDER,
    component: LoadableComponent(() => import('../views/Order')),
  },
  {
    name: resources.TRANSACTION.title,
    key: resources.TRANSACTION.key,
    resource: resources.TRANSACTION.key,
    path: routes.TRANSACTION,
    component: LoadableComponent(() => import('../views/Transactions')),
  },
  {
    name: resources.STATISTIC.title,
    key: resources.STATISTIC.key,
    resource: resources.STATISTIC.key,
    path: routes.STATISTIC,
    component: LoadableComponent(() => import('../views/Statistic')),
  },
  {
    name: resources.ACCOUNT_TRANS_DETAIL.title,
    key: resources.ACCOUNT_TRANS_DETAIL.key,
    resource: resources.ACCOUNT_TRANS_DETAIL.key,
    path: routes.ACCOUNT_TRANS_DETAIL,
    component: LoadableComponent(() => import('../views/Account/Transactions')),
  },
  {
    name: resources.ACCOUNT_ORDERS_DETAIL.title,
    key: resources.ACCOUNT_ORDERS_DETAIL.key,
    resource: resources.ACCOUNT_ORDERS_DETAIL.key,
    path: routes.ACCOUNT_ORDERS_DETAIL,
    component: LoadableComponent(() => import('../views/Account/Orders')),
  },
  {
    name: resources.ACCOUNT.title,
    key: resources.ACCOUNT.key,
    resource: resources.ACCOUNT.key,
    path: routes.ACCOUNT,
    component: LoadableComponent(() => import('../views/Account')),
  },
  {
    name: resources.APPS.title,
    key: resources.APPS.key,
    resource: resources.APPS.key,
    path: routes.APPS,
    component: LoadableComponent(() => import('../views/Apps')),
  },
  {
    name: resources.PRODUCTS.title,
    key: resources.PRODUCTS.key,
    resource: resources.PRODUCTS.key,
    path: routes.PRODUCTS,
    component: LoadableComponent(() => import('../views/Product')),
  },
  {
    name: resources.HTML_BLOCK_CREATE.title,
    key: resources.HTML_BLOCK_CREATE.key,
    resource: resources.HTML_BLOCK_CREATE.key,
    path: routes.HTML_BLOCK_CREATE,
    component: LoadableComponent(() => import('../views/HtmlBlock/create')),
  },
  {
    name: resources.HTML_BLOCK_EDIT.title,
    key: resources.HTML_BLOCK_EDIT.key,
    resource: resources.HTML_BLOCK_EDIT.key,
    path: routes.HTML_BLOCK_EDIT,
    component: LoadableComponent(() => import('../views/HtmlBlock/edit')),
  },
  {
    name: resources.HTML_BLOCK.title,
    key: resources.HTML_BLOCK.key,
    resource: resources.HTML_BLOCK.key,
    path: routes.HTML_BLOCK,
    component: LoadableComponent(() => import('../views/HtmlBlock')),
  },
  {
    name: resources.CAMPAIGN_CREATE.title,
    key: resources.CAMPAIGN_CREATE.key,
    resource: resources.CAMPAIGN_CREATE.key,
    path: routes.CAMPAIGN_CREATE,
    component: LoadableComponent(() => import('../views/Campaign/create')),
  },
  {
    name: resources.CAMPAIGN_EDIT.title,
    key: resources.CAMPAIGN_EDIT.key,
    resource: resources.CAMPAIGN_EDIT.key,
    path: routes.CAMPAIGN_EDIT,
    component: LoadableComponent(() => import('../views/Campaign/edit')),
  },
  {
    name: resources.CAMPAIGN.title,
    key: resources.CAMPAIGN.key,
    resource: resources.CAMPAIGN.key,
    path: routes.CAMPAIGN,
    component: LoadableComponent(() => import('../views/Campaign')),
  },
  {
    name: resources.CATALOG_CREATE.title,
    key: resources.CATALOG_CREATE.key,
    resource: resources.CATALOG_CREATE.key,
    path: routes.CATALOG_CREATE,
    component: LoadableComponent(() => import('../views/Catalog/create')),
  },
  {
    name: resources.CATALOG_EDIT.title,
    key: resources.CATALOG_EDIT.key,
    resource: resources.CATALOG_EDIT.key,
    path: routes.CATALOG_EDIT,
    component: LoadableComponent(() => import('../views/Catalog/edit')),
  },
  {
    name: resources.CATALOG.title,
    key: resources.CATALOG.key,
    resource: resources.CATALOG.key,
    path: routes.CATALOG,
    component: LoadableComponent(() => import('../views/Catalog')),
  },
  {
    name: resources.BLACKLIST.title,
    key: resources.BLACKLIST.key,
    resource: resources.BLACKLIST.key,
    path: routes.BLACKLIST,
    component: LoadableComponent(() => import('../views/Blacklist')),
  },
  {
    name: resources.DEALER_CREATE.title,
    key: resources.DEALER_CREATE.key,
    resource: resources.DEALER_CREATE.key,
    path: routes.DEALER_CREATE,
    component: LoadableComponent(() => import('../views/Dealer/create')),
  },
  {
    name: resources.DEALER.title,
    key: resources.DEALER.key,
    resource: resources.DEALER.key,
    path: routes.DEALER,
    component: LoadableComponent(() => import('../views/Dealer')),
  },
  {
    name: resources.RESET_PASSWORD.title,
    key: resources.RESET_PASSWORD.key,
    resource: resources.RESET_PASSWORD.key,
    path: `${routes.RESET_PASSWORD}`,
    component: LoadableComponent(() => import('../views/ResetPassword')),
  },
  {
    path: routes.LOGIN,
    component: LoadableComponent(() => import('../views/Authorization/Login')),
    unProtected: true,
  },
  {
    path: routes.HOME_PAGE,
    component: LoadableComponent(() =>
      import('../components/PageAuthorization')
    ),
    unProtected: true,
  },
]
