const RIOT_KEY = 'user'
const FILE = 'file'
const ORDER = 'order'
const BLACKLIST = 'blacklist'
const DEALER = 'dealer'
const AUTHORIZATION = 'authorization'
const RESETPASSWORD = 'reset-password'
const STATISTIC = 'statistic'
const ACCOUNT = 'account'
const APPS = 'apps'
const PRODUCTS = 'products'
const HTML_BLOCK = 'html-block'
const TRANSACTION = 'transaction'
const CAMPAIGN = 'campaign'
const CATALOG = 'catalog'

export default {
  HOME_PAGE: '/',
  RIOT_KEY: `/${RIOT_KEY}`,
  FILE: `/${FILE}`,
  ORDER: `/${ORDER}`,
  BLACKLIST: `/${BLACKLIST}`,
  DEALER: `/${DEALER}`,
  RESETPASSWORD: `/${RESETPASSWORD}`,
  AUTHORIZATION: `/${AUTHORIZATION}`,
  STATISTIC: `/${STATISTIC}`,
  ACCOUNT: `/${ACCOUNT}`,
  APPS: `/${APPS}`,
  PRODUCTS: `/${PRODUCTS}`,
  HTML_BLOCK: `/${HTML_BLOCK}`,
  TRANSACTION: `/${TRANSACTION}`,
  CAMPAIGN: `/${CAMPAIGN}`,
  CATALOG: `/${CATALOG}`,
}
