import { sortBy } from 'lodash'

export const updateRecursiveArray = (currentLists = [], item = null) => {
  if (!item) {
    return currentLists
  }
  const newData = currentLists?.map((value) => {
    const { id } = value // no default value for children

    if (item.id === id) {
      return Object.assign(value, item)
    }

    const children = updateRecursiveArray(value.children, item)

    return Object.assign(value, {
      children: children?.length > 0 ? children : null
    })
  })

  return newData
}

export const sortRecursiveArray = (currentLists = [], fieldSort) => {
  return sortBy(currentLists, fieldSort)
}
