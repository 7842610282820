import React, { memo, useRef, createRef } from 'react'
// lib
import PropTypes from 'prop-types'
import classNames from 'classnames'
import Button from 'antd/lib/button'
import Modal from 'antd/lib/modal'
// component
import CustomButtonHeader from './CustomButtonHeader'

const FormModal = (props) => {
  const {
    isMobile,
    title,
    open,
    handleCloseModal,
    okText,
    cancelText,
    className,
  } = props

  const buttonRef = useRef(createRef())

  const initPropsToComponent = props.children
    ? React.cloneElement(props.children, {
        buttonSubmit: (
          <button ref={buttonRef} type='submit' className='d-none' />
        ),
      })
    : null

  return (
    <Modal
      mask={false}
      closable={false}
      maskClosable={false}
      visible={open}
      cancelText={cancelText ? cancelText : 'Cancel'}
      okText={okText ? okText : 'Confirm'}
      className={classNames(`${className}`)}
      autoFocusButton='cancel'
      title={
        <h4 className='text-center text-uppercase font-weight-bold'>{title}</h4>
      }
      footer={
        <div className='d-flex align-items-center justify-content-end'>
          <Button
            onClick={() => {
              if (handleCloseModal) {
                handleCloseModal()
              }
            }}
          >
            Cancel
          </Button>
          <Button
            type='primary'
            onClick={() => {
              if (buttonRef.current) {
                buttonRef.current.click()
              }
            }}
          >
            Accept
          </Button>
        </div>
      }
    >
      {initPropsToComponent}
    </Modal>
  )
}

FormModal.propTypes = {
  title: PropTypes.any,
  open: PropTypes.bool,
  handleCloseModal: PropTypes.func,
  okText: PropTypes.any,
  cancelText: PropTypes.any,
  className: PropTypes.func,
}

export default memo(FormModal)
