import React from 'react'

export const validateError = {
  required: 'This field is required.',
  email: 'Please enter a valid email address.',
  url: 'Please enter a valid URL.',
  confirmPassword: 'Password do not match',
  passwordFormat:
    'Use 6 or more characters with a mix of letters, numbers & symbols',
  codeFormat: 'Do not use symbols and space.',
  duplicate: 'Item exits',
  duplicateGroup: 'Groups must not be the same.',
  upperCase: 'Only English Capitals.',
  nameCommunity: 'Only insert characters and numbers.',
  validateUploadPopup: 'Please make sure you do not have any missing content.',
  validateContentGroup: 'You must select content group.',
  lengthString: (fieldName, length) =>
    `${fieldName} length must be equal ${length}`,
  selection: (fieldName) => `You must select ${fieldName}`,
  maximumLengthString: (fieldName, maximum) =>
    `${fieldName} length must be smaller or equal ${maximum}`,
  minimumLengthString: (fieldName, minimum) =>
    `${fieldName} length must be greater or equal ${minimum}`,
  minimumSelection: (fieldName, minimum) =>
    `You must select at least ${minimum} ${fieldName}`,
  maximumSelection: (fieldName, maximum) =>
    `You can only select maximum ${maximum} ${fieldName}`,
  minimumNumber: (fieldNumber, minimum) =>
    `${fieldNumber} must be greater than ${minimum}`,
  maximumNumber: (fieldNumber, maximum) =>
    `${fieldNumber} must be smaller than ${maximum}`,
  number: 'Please enter a valid number.',
  endDate: 'End date must be greater than start date.',
  withoutSpecialCharacter: (fieldName) =>
    `${fieldName} must not contain special character.`,
  withoutEmoji: (fieldName) => `${fieldName} must not contain emoji.`,
  notSameField: (fieldName, fieldCompare) =>
    `${fieldName} must not be the same ${fieldCompare}.`,
  userAvailable: (value) => `Only ${value} user is available.`,
}

export const errorType = {
  BAD_EMAIL_INPUT: 'BAD_EMAIL_INPUT',
  BAD_USERNAME_INPUT: 'BAD_USER_INPUT',
  UNAUTHENTICATED: 'UNAUTHENTICATED',
  CANNOT_ACCESS: 'CANT_ACCESS',
  BANNED_WORD_DUPLICATE: 'BANNED_WORD_DUPLICATE',
  ACCESS_AUTHORITY_NAME_DUPLICATE: 'ACCESS_AUTHORITY_NAME_DUPLICATE',
  ACCESS_AUTHORITY_CODE_DUPLICATE: 'ACCESS_AUTHORITY_CODE_DUPLICATE',
  REQUIRED: 'This field is required.',
  EMAIL: 'Please enter a valid email address.',
  URL: 'Please enter a valid URL.',
  NUMBER: 'Please enter a valid number',
}

export default {
  validateError,
  errorType,
}
