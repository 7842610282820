export const PAGE_RIOT_KEY = 'Riot Keys'
export const PAGE_USER_SESSION = 'Riot Key Session'
export const PAGE_FILE = 'Files'
export const PAGE_ORDER = 'Orders'
export const PAGE_BLACKLIST = 'Blacklist'
export const PAGE_DEALER = 'Dealer'
export const PAGE_APP = 'Apps'
export const PAGE_PRODUCT = 'Products'
export const PAGE_RESETPASSWORD = 'Reset Password'
export const PAGE_ACCOUNT = 'Account'
export const PAGE_HTML_BLOCK = 'Html Block'
export const PAGE_CAMPAIGN = 'Campaign'
export const PAGE_CATALOG = 'Catalog'
export const PAGE_TRANSACTION = 'Transaction'
export const SINGLE_USER = 'Riot Key'
export const SINGLE_TRANSACTION = 'transaction'
export const SINGLE_SESSION = 'session'
export const SINGLE_FILE = 'file'
export const SINGLE_ORDER = 'order'
export const SINGLE_APP = 'app'
export const SINGLE_PRODUCT = 'product'
export const SINGLE_BLACKLIST = 'blacklist'
export const SINGLE_DEALER = 'dealer'
export const SINGLE_RESETPASSWORD = 'resetPassword'
export const SINGLE_ACCOUNT = 'account'
export const SINGLE_HTMLBLOCK = 'htmlBlock'
export const SINGLE_CAMPAIGN = 'campaign'
export const SINGLE_CATALOG = 'catalog'

export const BUTTON_CREATE = 'Create'
export const BUTTON_CREATE_MANY = 'Create Many'
export const BUTTON_CANCEL = 'Cancel'
export const BUTTON_SAVE = 'Save'
export const BUTTON_UPDATE = 'Update'
export const BUTTON_DELETE = 'Delete Selected User'
export const BUTTON_BLOCK = 'Block'
export const BUTTON_UN_BLOCK = 'UnBlock'
export const BUTTON_RESTORE = 'Restore'
export const BUTTON_EDIT = 'Edit'
export const BUTTON_LOGIN = 'Login'
export const BUTTON_RESET_PASSWORD = 'Change Password'

export const COL_INDEX = 'Index'
export const COL_NAME = 'Key'
export const COL_EMAIL = 'Email'
export const COL_DEALER = 'Dealer'
export const COL_APP = 'Key Type Id'
export const COL_KEY_TYPE = 'Key Type'
export const COL_ACCOUNT_TYPE = 'Type'
export const COL_USER_PREFIX = 'Prefix'
export const COL_USERID = 'UserId'
export const COL_TIME_ADDED = 'Time'
export const COL_PROMOTION = 'Ads'
export const COL_SELLY = 'Selly'
export const COL_ACTIVE = 'Used'
export const COL_IS_TIME_ADDED = 'Extended'
export const COL_IS_PROMOTION = 'isPromotion'
export const COL_BLOCK = 'Block'
export const COL_TOTAL_LOGIN = 'Total'
export const COL_EXPIRED = 'Expired'
export const COL_FIRST_LOGIN = 'FirstLogin'
export const COL_LAST_LOGIN = 'LastLogin'
export const COL_LOGGED = 'Logged'
export const COL_CREATED_AT = 'Created At'
export const COL_UPDATED_AT = 'Updated At'
export const COL_ACTION = 'Action'
export const COL_EXPIRED_AT = 'Expired'
export const COL_FIRST_LOGIN_AT = 'First Login'
export const COL_FAIL_TYPE = 'Fail Type'
export const COL_BLOCKED_DATA = 'Blocked Data'
export const COL_BLACKLIST_TYPE = 'Blacklist Type'
export const COL_BLACKLIST_ID = 'Blacklist Selly Id'
export const COL_CODE = 'Code'
export const COL_TRANSACTION_TYPE = 'Transaction Type'
export const COL_FUNDS = 'Funds'
export const COL_DISCOUNT = 'Discount Amount'
export const COL_INVOICE_ID = 'InvoiceId'
export const COL_CAMPAIGN_NAME = 'Name'
export const COL_DESCRIPTION = 'Description'
export const COL_PRIORITY = 'Priority'
export const COL_CONTENT = 'Content'
export const COL_MIN_VALUE = 'Min Value'
export const COL_MAX_VALUE = 'Max Value'
export const COL_PERCENT = 'Percent'
export const COL_START_TIME = 'Start'
export const COL_END_TIME = 'End'

export const LABEL_NAME = 'Name'
export const LABEL_USERNAME = 'Username'
export const LABEL_PREFIX = 'Prefix'
export const LABEL_PARENT = 'Parent'
export const LABEL_EMAIL = 'Email'
export const LABEL_PRICE = 'Price'
export const LABEL_UNIT = 'Unit'
export const LABEL_PASSWORD = 'Password'
export const LABEL_ACTIVE_STATUS = 'Active'
export const LABEL_BLOCK_STATUS = 'Block'
export const LABEL_PROMOTION = 'Promotion'
export const LABEL_PROMOTION_STATUS = 'Ads'
export const LABEL_ACCOUNT_TYPE = 'Account Type'
export const LABEL_ACCOUNT_ADD_TIME = 'Add More Time'
export const LABEL_CREATE_APP = 'Create App'
export const LABEL_UPDATE_APP = 'Update App'
export const LABEL_EDIT_APP = 'New Title'
export const LABEL_ACCOUNT_ADD_MONEY = 'Add Money'
export const LABEL_CURRENCY = 'Currency'
export const LABEL_FUNDS = 'Funds'
export const LABEL_FEES = 'Fee'
export const LABEL_NUMBER_OF_USERS = 'Number Of Users'
export const LABEL_CHANGE_PASSWORD = 'Change Password'
export const LABEL_RETYPE_PASSWORD = 'Retype Password'
export const LABEL_RESET_PASSWORD = 'Reset Password'
export const LABEL_TOTAL = 'Total'
export const LABEL_SUCCESS = 'Success'
export const LABEL_FAIL = 'Fail'
export const LABEL_CODE = 'Code Name'
export const LABEL_CONTENT = 'Content'
export const LABEL_MINUS = 'Minus'
export const LABEL_PLUS = 'Plus'
export const LABEL_COMPLETED = 'Completed'
export const LABEL_GATEWAYS = 'Gateways'
export const LABEL_DESCRIPTION = 'Description'
export const LABEL_MIN_VALUE = 'Min Value'
export const LABEL_MAX_VALUE = 'Max Value'
export const LABEL_TYPE = 'Type'
export const LABEL_PERCENT = 'Percent'
export const LABEL_RANGE_TIME = 'Range Time'
export const LABEL_PRIORITY = 'Priority'
export const LABEL_APP = 'App'
export const LABEL_PRODUCT_CONTENT = 'Product Content'

export const COL_USERNAME = 'Username'
export const COL_PASSWORD = 'Password'
export const COL_RESET_HARDWARE = 'Reset'
export const COL_SESSION_ID = 'SessionID'
export const COL_IP = 'IP'
export const COL_COUNTRY_CODE = 'Country'
export const COL_CPU_ID = 'CPUID'
export const COL_HARDWARE_ID = 'HardwareID'
export const COL_TICK_DECIMAL = 'TickDecimal'
export const COL_SERVER_IP = 'ServerIp'
export const COL_SERVER_COUNTRY_CODE = 'Server'
export const COL_PC_USER = 'PcUser'
export const COL_PC_NAME = 'PcName'
export const COL_BALANCE = 'Balance'
export const COL_BALANCE_PROMO = 'Balance Promo'
export const COL_CURRENCY = 'Currency'
export const COL_PC_OS = 'PcOS'
export const COL_REQUEST = 'Request'
export const COL_RESPONSE = 'Response'
export const COL_SUCCESS = 'Success'
export const COL_EXTENDED = 'Extended'
export const COL_STATUS = 'Status'
export const COL_QUANTITY = 'Quantity'
export const COL_TOTAL_TOPUP = 'TopUp'
export const COL_TOTAL_SPENDING = 'Spending'
export const COL_TOTAL_PROMO = 'Promo'
export const COL_SHOW = 'Show'
export const COL_SOLD = 'Sold'
export const COL_PRODUCT_CONTENT = 'Product Content'

export const COL_FILENAME = 'File Name'
export const COL_USER_COUNT = 'User Count'

export const COL_ORDER = 'OrderId'
export const COL_TRANSACTION = 'TransactionId'
export const COL_BLACKLIST = 'BlacklistId'
export const COL_DEALER_ID = 'DealerId'
export const COL_RESETPASSWORD = 'Reset Password Id'
export const COL_ACCOUNT = 'AccountId'
export const COL_APP_TYPE = 'App Type'
export const COL_GATEWAY = 'Gateway'
export const COL_VALUE = 'Value'
export const COL_AMOUNT = 'Amount'
export const COL_AMOUNT_PROMO = 'Amount Promo'
export const COL_REAL_VALUE = 'Revenue'
export const COL_BANK_CODE = 'Code'
