import React from 'react'
import {
  UserOutlined,
  FileOutlined,
  ShoppingOutlined,
  ExclamationCircleOutlined,
  TeamOutlined,
  CodeOutlined,
  TransactionOutlined,
  HistoryOutlined,
  BarChartOutlined,
  SolutionOutlined,
  SettingOutlined,
  RiseOutlined,
  GoldOutlined,
  HddOutlined,
  KeyOutlined,
} from '@ant-design/icons'
import { routes, resources } from '../routes'

export default [
  {
    name: resources.ACCOUNT.title,
    key: resources.ACCOUNT.title,
    resource: resources.ACCOUNT.key,
    path: routes.ACCOUNT,
    icon: <SolutionOutlined />,
  },
  {
    name: resources.RIOT_KEY_LIST.title,
    key: resources.RIOT_KEY_LIST.title,
    resource: resources.RIOT_KEY_LIST.key,
    path: routes.RIOT_KEY_LIST,
    icon: <KeyOutlined />,
  },
  {
    name: resources.CATALOG.title,
    key: resources.CATALOG.title,
    resource: resources.CATALOG.key,
    path: routes.CATALOG,
    icon: <GoldOutlined />,
  },
  {
    name: resources.APPS.title,
    key: resources.APPS.title,
    resource: resources.APPS.key,
    path: routes.APPS,
    icon: <SettingOutlined />,
  },
  {
    name: resources.PRODUCTS.title,
    key: resources.PRODUCTS.title,
    resource: resources.PRODUCTS.key,
    path: routes.PRODUCTS,
    icon: <HddOutlined />,
  },
  {
    name: resources.DEALER.title,
    key: resources.DEALER.title,
    resource: resources.DEALER.key,
    path: routes.DEALER,
    icon: <TeamOutlined />,
  },
  {
    name: resources.RESET_PASSWORD.title,
    key: resources.RESET_PASSWORD.title,
    resource: resources.RESET_PASSWORD.key,
    path: routes.RESET_PASSWORD,
    icon: <HistoryOutlined />,
  },
  {
    name: resources.ORDER.title,
    key: resources.ORDER.title,
    resource: resources.ORDER.key,
    path: routes.ORDER,
    icon: <ShoppingOutlined />,
  },
  {
    name: resources.TRANSACTION.title,
    key: resources.TRANSACTION.title,
    resource: resources.TRANSACTION.key,
    path: routes.TRANSACTION,
    icon: <TransactionOutlined />,
  },
  {
    name: resources.CAMPAIGN.title,
    key: resources.CAMPAIGN.title,
    resource: resources.CAMPAIGN.key,
    path: routes.CAMPAIGN,
    icon: <RiseOutlined />,
  },
  {
    name: resources.FILE.title,
    key: resources.FILE.title,
    resource: resources.FILE.key,
    path: routes.FILE,
    icon: <FileOutlined />,
  },
  {
    name: resources.HTML_BLOCK.title,
    key: resources.HTML_BLOCK.title,
    resource: resources.HTML_BLOCK.key,
    path: routes.HTML_BLOCK,
    icon: <CodeOutlined />,
  },
  {
    name: resources.STATISTIC.title,
    key: resources.STATISTIC.title,
    resource: resources.STATISTIC.key,
    path: routes.STATISTIC,
    icon: <BarChartOutlined />,
  },

  {
    name: resources.BLACKLIST.title,
    key: resources.BLACKLIST.title,
    resource: resources.BLACKLIST.key,
    path: routes.BLACKLIST,
    icon: <ExclamationCircleOutlined style={{ color: 'red' }} />,
  },
]
