import _ from 'lodash'
import { numberExtensions } from '.'

export default {
  getErrorCode(error) {
    let field = null
    let message = null
    let extensionInfo = null
    if (error && error.extensions) {
      const { extensions } = error
      field = extensions.code
      extensionInfo = extensions
    }

    return {
      field,
      message,
      extensionInfo: extensionInfo,
    }
  },
  formatObjectSelect: ({ rawValue, valueField, labelField }) => {
    if (rawValue) {
      return {
        ...rawValue,
        key: valueField ? rawValue[valueField] : rawValue.id,
        value: valueField ? rawValue[valueField] : rawValue.id,
        label: labelField ? rawValue[labelField] : rawValue.name,
      }
    }
    return null
  },

  formatImageObject: (id, url) => {
    return url
      ? {
          uid: id || null,
          status: 'done',
          url: url,
        }
      : null
  },

  getValueOption(data, originValue) {
    if (data && data.length > 0) {
      if (_.isArray(originValue)) {
        let optionSelected = originValue.map((item) => {
          if (item.id) {
            return {
              ...item,
              value: item.id,
              label: item.name,
              key: item.id,
            }
          } else if (item.value) {
            return {
              ...item,
              value: item.value,
              label: item.label,
              key: item.value,
            }
          } else if (item.key) {
            return {
              ...item,
              value: item.key,
              label: item.label,
            }
          } else {
            const value = _.find(data, (x) => x.value === item)
            if (value) {
              return {
                ...value,
                key: value.value,
              }
            }
            return null
          }
        })
        optionSelected = _.compact(optionSelected)
        optionSelected = _.filter(optionSelected, (item) => item.value)
        return optionSelected
      }
      if (_.isObject(originValue)) {
        let valueOption = originValue
        if (originValue.id) {
          valueOption = originValue.id
        } else if (originValue.value) {
          valueOption = originValue.value
        } else if (originValue.key) {
          valueOption = originValue.key
        }
        let optionSelected = _.find(
          data,
          (item) => item.value === valueOption || item.code === valueOption
        )
        if (optionSelected) {
          optionSelected = {
            ...originValue,
            ...optionSelected,
          }
        }
        return optionSelected
      }
      if (
        _.isString(originValue) ||
        _.isNumber(originValue) ||
        typeof originValue === 'boolean'
      ) {
        let optionSelected = _.find(
          data,
          (item) =>
            item.value === originValue ||
            item.code === originValue ||
            item.key === originValue
        )
        if (optionSelected) {
          return {
            ...optionSelected,
            key: originValue,
          }
        }
        //return _.find(data, (item) => item.value === originValue || item.code === originValue)
      }
    }
    // if (originValue) {
    //   return originValue
    // }
    return undefined
  },

  getCountAndDataGrid({
    resultQuery,
    connectionField,
    dataGridField,
    pageSize,
    pageIndex,
  }) {
    let countConnection = 0
    let dataGrid = []
    if (resultQuery) {
      if (
        resultQuery[connectionField] &&
        resultQuery[connectionField].fullCount > 0
      ) {
        countConnection = resultQuery[connectionField].fullCount
      }

      if (resultQuery[dataGridField] && resultQuery[dataGridField].length > 0) {
        dataGrid = resultQuery[dataGridField].map((item, index) => ({
          ...item,
          index:
            pageSize && pageIndex
              ? numberExtensions.formatNumber(countConnection - index)
              : index,
        }))
      }
    }

    return {
      total: countConnection,
      dataGrid: dataGrid,
    }
  },

  initValueToOption(optionSelected, listOptions) {
    if (optionSelected) {
      if (_.isArray(optionSelected)) {
        optionSelected.forEach((option) => {
          const index = listOptions.findIndex(
            (item) => item.value == option.value
          )
          if (index < 0) {
            listOptions.push(option)
          }
        })
      } else if (_.isObject(optionSelected)) {
        const index = listOptions.findIndex(
          (item) => item.value == optionSelected.value
        )
        if (index < 0) {
          listOptions.push(optionSelected)
        }
      }
    }

    return listOptions
  },

  getReporterName(reporter, fullName) {
    if (!reporter) {
      return fullName
    }
    return reporter.username ? reporter.username : null
  },
}
